import React, { useState, useEffect } from "react";
import { useMatch, Link } from "react-router-dom";
import { isLoggedIn } from "../services/auth"
import { fetchData } from '../utilities/async';

import NavPrimary from './NavPrimary.js';
import Button from './TagButton';
import SvgComponent from './SvgComponent';


const Header = ({ isDarkMode, setDarkMode }) => {
  let match = useMatch("/");
  const endpoint = '/json/kidkrazydotnet-nav.json';
  // state
  const [dataUrl, setDataUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  //routes
  const [linkPublic, setLinkPublic] = useState([]);
  const [linkSecure, setLinkSecure] = useState([]);
  const [linkSocial, setLinkSocial] = useState([]);

  const [header, setHeader] = useState(false);
  const [trigger] = useState('10');
  const [isNav, setIsNav] = useState(false);


  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(endpoint).then((ActualData) => {
      setDataUrl(ActualData.data);
      setLinkPublic(ActualData.data[0].public)
      setLinkSecure(ActualData.data[1].secure)
      setLinkSocial(ActualData.data[2].social)
      setError(null);
    })
      .catch((err) => {
        setError(err.message);
        setDataUrl(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [endpoint]);

  // nav routes
  let linkAll = [...linkPublic, ...linkSecure];

  // nav effects
  useEffect(() => {

    const handleScroll = () => {
      const scrollTop = window.innerHeight;
      const scrollCurrent = window.scrollY;

      if (Math.abs(scrollTop - scrollCurrent) > trigger) {
        setHeader(() => !header);
      }

      if (Math.abs(scrollCurrent <= trigger)) {
        setHeader(() => header);
      }
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [trigger]);

  // listen for nav to lock body and toggle class
  useEffect(() => {
    let body = document.body;

    if (isNav === true) {
      body.classList.add("locked");
    }
    if (isNav === false) {
      body.classList.remove("locked");
    }
  }, [isNav]);

  const toggleNav = (e) => {
    setIsNav(!isNav);
  }

  const resetNav = () => {
    setIsNav(false)
  }

  const darkMode = (e) => {
    setDarkMode(!isDarkMode)
  }

  const Logo = (
    <Link to="/" className='nav__home' name='Home' aria-label='Home' onClick={resetNav}>
      <SvgComponent name="brand" svgClass="nav__home--svg" />
    </Link>
  );

  const Toggle = (
    <Button
      buttonType='button'
      className={isNav ? "nav__toggle nav__toggle--close" : "nav__toggle"}
      name={isNav ? "navigation close" : "navigation open"}
      aria={isNav ? "navigation close" : "navigation open"}
      onClick={(e) => toggleNav(e)}
      icon={true}
    />
  )

  const Nav = (
    <NavPrimary
      isNav={isNav}
      setIsNav={setIsNav}
      toggleNav={toggleNav}
      isDarkMode={isDarkMode}
      darkMode={darkMode}
      links={!isLoggedIn() ? linkPublic : linkAll}
      social={linkSocial}
    />
  )

  if (loading) return null;

  if (error || !dataUrl) {
    return <p>There was an error loading your data!</p>;
  }

  if (dataUrl.length > 0) {
    return (
      <>
        <div className={'header'}>
          {match ? '' : Logo}
          {Toggle}
          {Nav}
        </div>
        <div className={`header__bar ${header ? 'header__bar--on' : 'header__bar--off'}`} />
      </>
    )
  }
}

export default Header;