import React, { useEffect } from "react";
import Video from '../components/Video';
import Iframe from '../components/Iframe';

const VideoItem = (props) => {
  const { className, onClick, poster, source, src, type, title, summary, summaryFull, role, keywords, date, runtime } = props;

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      className={className}
      onClick={onClick}
    >
      <div className={className + '--wrapper'}>
        <div
          className={className + '--video'}
        >
          {source ?
            <Video
              poster={poster}
              preload='true'
              source={source}
              type={type}
              controlsList="nodownload"
            />
            : ''
          }
          {src ?
            <Iframe
              preload='true'
              src={src}
              controls="0"
            />
            : ''
          }
        </div>
      </div>
      {title ? <h2>{title}</h2> : ''}
      {summary ? <h5>{summary}</h5> : ''}
      <hr className="divider" />
      {summaryFull ? <p>{summaryFull}</p> : ''}
      <hr className="divider" />
      {role ? <p><strong>Role:</strong> {role}</p> : ''}
      {keywords ? <p><strong>Keywords:</strong> {keywords}</p> : ''}
      {date ? <p><strong>Date:</strong> {date}</p> : ''}
      {runtime ? <p><strong>Runtime:</strong> {runtime}</p> : ''}
    </div>
  )
}

export default VideoItem;