import React, { } from "react";

const Button = (props) => {

	const {
		buttonType, className, disabled, onClick, onKeyUp, title, icon, destination, target,
		name, type, value, aria, tabIndex, children
	} = props;

	return (
		<>
			{buttonType === 'anchor'
				? <a
					className={disabled ? className + '--disabled' : className}
					onClick={onClick}
					onKeyUp={onKeyUp}
					aria-label={aria ? aria : title}
					href={destination}
					target={target ? target : '_blank'}
					rel="norefferer"
					tabIndex={tabIndex ? tabIndex : '0'}
				>
					{icon
						? <i />
						: ''
					}
					{title}
					{children}
				</a>
				: ''
			}

			{buttonType === 'button'
				? <button
					className={disabled ? className + '--disabled' : className}
					onClick={onClick}
					onKeyUp={onKeyUp}
					name={name}
					aria-label={aria ? aria : name}
					buttontype={type}
					value={value}
					tabIndex={tabIndex ? tabIndex : '0'}
				>
					{icon
						? <i />
						: ''
					}
					{title}
					{children}
				</button>
				: ''
			}

			{buttonType === 'div'
				? <div
					className={disabled ? className + '--disabled' : className}
					onClick={onClick}
					onKeyUp={onKeyUp}
					tabIndex={tabIndex ? tabIndex : '0'}
				>
					{icon
						? <i />
						: ''
					}
					{title}
					{children}
				</div>
				: ''
			}
		</>
	)
}

export default Button;