import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TagInput from "../components/TagInput";
import Button from "../components/TagButton";

const Contact = (props) => {
  // const [submit, setSubmit] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [inputs, setInputs] = useState({});

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setInputs(values => ({ ...values, [id]: value }))
  }

  // when submit btn is clicked
  const onSubmit = (e) => {
    e.preventDefault();

    // check field validation here
    if (true === false) {
      return false;
    }

    // create a new XMLHttpRequest
    var xhr = new XMLHttpRequest();

    // get a callback when the server responds
    xhr.addEventListener("load", () => {
      // update the response state and the step
      setEmailStatus(xhr.responseText);
    });

    // open the request with the verb and the url
    xhr.open(
      "GET",
      process.env.REACT_APP_MAILER +
      "?sendto=" +
      inputs.email +
      "&name=" +
      inputs.name +
      "&phone=" +
      inputs.phone +
      "&message=" +
      inputs.message
    );

    // send the request
    xhr.send();

    // reset the fields
    setInputs("");
  };

  if (emailStatus !== false) {
    return (
      <div className="response">
        {/* <p>{emailStatus}</p> */}
        <p>I'll be in touch soon, in the meantime...</p>
        <Link to={"/About"} className="btn__text">
          Learn more about me.
        </Link>
      </div>
    );
  }

  return (
    <form id="contact-form" onSubmit={onSubmit} method="POST">
      <header>
        <h2>Let's Talk</h2>
        <p>Feel free to send me a message by filling out the form below.</p>
      </header>
      <TagInput
        inputType="input"
        className="element"
        label="Name"
        id="name"
        type="text"
        value={inputs.name || ""}
        onChange={handleChange}
        placeholder="Name"
        required={true}
      />
      <TagInput
        inputType="input"
        className="element"
        label="Email"
        id="email"
        type="email"
        value={inputs.email || ""}
        onChange={handleChange}
        placeholder="Email"
        required={true}
      />
      <TagInput
        inputType="input"
        className="element"
        label="Phone"
        id="phone"
        type="tel"
        pattern="[0-9]{3}[0-9]{3}[0-9]{4}"
        value={inputs.phone || ""}
        onChange={handleChange}
        placeholder="0000000000"
        required={true}
      />
      <TagInput
        inputType="textarea"
        className="element"
        label="Message"
        id="message"
        rows="5"
        value={inputs.message || ""}
        onChange={handleChange}
        placeholder="Message"
        required={true}
      />
      <Button
        buttonType="button"
        className="btn__basic"
        type="submit"
        title="Send Message"
      />
    </form>
  );
};

Contact.propTypes = {
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  message: PropTypes.string,
};

export default Contact;