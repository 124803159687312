import React, { useState, useEffect } from "react";
import Markdown from "markdown-to-jsx";

import { fetchMd } from '../utilities/async';
import LayoutHeader from './LayoutHeader';
import Loader from '../components/Loader';
import TagFigure from "../components/TagFigure";
import GallerySingle from '../components/GallerySingle';
// import { getScrollEval } from '../utilities/scroll';

const LayoutMarkdown = (props) => {
  
  // props
  let {pageClass, endpoint, imagePath} = props;

  // state
  const [data, setData] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gallerySingle, setGallerySingle] = useState(false);
  const [gallerySingleImage, setGallerySingleImage] = useState(null);

  let body = document.body;
  // let pageClass = 'markdown';

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchMd(endpoint).then((ActualData) => {
      setData(ActualData.data);
      setError(null);
    })
      .catch((err) => {
        setError(err.message);
        setData(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [endpoint]);

  const gallerySingleOpen = (e) => {
    e.preventDefault();
    setGallerySingleImage(e.target.firstElementChild.src);
    setGallerySingle(!gallerySingle);
    body.classList.add("locked");
  }

  const gallerySingleClose = (e) => {
    e.preventDefault();
    setGallerySingleImage('');
    setGallerySingle(!gallerySingle);
    body.classList.remove("locked");
  }

  const Figure = props => {
    return (
      <TagFigure
        key={props.src}
        className={`${pageClass}__media`}
        src={imagePath + props.src}
        alt={props.alt}
        caption={props.alt}
        onClick={(e) => gallerySingleOpen(e)}
        onKeyPress={(e) => gallerySingleOpen(e)}
        tabIndex={0}
      />
    );
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', (event) => getScrollEval(event, `${pageClass}__content h2`));

  //   return () => {
  //     window.removeEventListener('scroll', getScrollEval);
  //   }
  // }, [pageClass])

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <>
      <LayoutHeader data={props.header} className={`${pageClass}__header`}/>
      <div className={`${pageClass}__content`}>
        <Markdown
          children={data}
          options={{
            wrapper: React.Fragment,
            overrides: {
              img: {
                component: Figure
              },
            },
          }}
        />
      </div>
      {gallerySingle ?
        <GallerySingle
          closeGallery={(e) => gallerySingleClose(e)}
          data={gallerySingleImage}
          figure={true}
        />
        : ''
      }
    </>
  )
}

export default LayoutMarkdown;