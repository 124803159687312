const isBrowser = (typeof window !== `undefined`)

const getUser = () => (
  window.localStorage.kidkrazyUser
    ? JSON.parse(window.localStorage.kidkrazyUser)
    : {}
);

const setUser = user => (window.localStorage.kidkrazyUser = JSON.stringify(user));

export const handleLogin = ({ username, password }) => {
  if (!isBrowser) return false
  
  if (username === `hello` && password === `welcome`) {
    return setUser({
      username: `hello`,
      name: `visitor`,
      // email: `yeah@nah.nope`,
    })
  }
  return false
}

export const isLoggedIn = () => {
  if (!isBrowser) return false

  const user = getUser()
  
  return !!user.username
}

export const getCurrentUser = () => isBrowser && getUser()

export const logout = callback => {
  if (!isBrowser) return

  // console.log(`Ensuring the \`recipeUser\` property exists.`)
  localStorage.clear();
  // setUser({})
  // callback()
}