import React, { useState, useEffect } from "react";

import { fetchData } from '../utilities/async';
import LayoutHeader from '../Layout/LayoutHeader';
import Loader from '../components/Loader';

import ProjectSearchInput from '../components/ProjectSearchInput';
import ProjectsSearchResults from '../components/ProjectsSearchResults';
import ProjectDetail from '../components/ProjectDetail';

const Projects = (props) => {
  // data
  const { REACT_APP_CDN } = process.env;
  const endpointWeb = '/json/kidkrazydotnet-web.json';
  const endpointVideo = '/json/kidkrazydotnet-video.json';
  const endpointDesign = '/json/kidkrazydotnet-design.json';
  //pathing
  const imagePath = REACT_APP_CDN + '/_images/thumbs/';
  const imagePathFull = REACT_APP_CDN + '/images/';
  const imagePathHero = REACT_APP_CDN + '/images/hero/';
  const videoPath = REACT_APP_CDN + '/videos/';

  // state
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [dataUrlWeb, setDataUrlWeb] = useState([]);
  const [dataUrlVideo, setDataUrlVideo] = useState([]);
  const [dataUrlDesign, setDataUrlDesign] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const [details, setDetails] = useState(false);
  const [viewList, setViewList] = useState(true);
  const [selectedProject, setSelectedProject] = useState(0);
  const currentView = dataAll[selectedProject];

  // Run once on first render
  useEffect(() => {
    // window.scrollTo(0, 0);
    Promise.all([
      fetchData(endpointWeb),
      fetchData(endpointDesign),
      fetchData(endpointVideo)
    ])
      .then(([web, design, video]) => {
        setDataUrlWeb(web.data)
        setDataUrlDesign(design.data)
        setDataUrlVideo(video.data)
        // console.group('web: ', web, 'design:', design, 'video:', video)
        let all = [].concat(web.data, design.data, video.data)
        // console.log('dataAll:', all)
        setDataAll(all)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const detailOpen = (e, index) => {
    setDetails(true);
    setSelectedProject(index);
  }

  const detailClose = () => {
    setDetails(!setDetails);
    setSearchTerm('');
  }

  const detailPrev = () => {
    if (selectedProject === 0) {
      detailClose();
    }
    setSelectedProject(selectedProject - 1);
    window.scrollTo(0, 0);
  }

  const detailNext = () => {
    const projectTotal = dataAll.length;

    if (selectedProject === projectTotal - 1) {
      detailClose();
    }
    setSelectedProject(selectedProject + 1);
    window.scrollTo(0, 0);
  }

  // sort by type && date desc
  const sorted = dataAll.sort((a, b) => {
    if (a.type === b.type) {
      // type is only important when date is the same
      return b.date - a.date;
    }
    return a.date > b.date ? -1 : 1;
  });
  // console.log('sorted', dataAll.sort((a, b) => b.date - a.date));

  if (loading) return <Loader />;

  if (error || !sorted) {
    return <p>There was an error loading your data!</p>;
  }

  if (details && sorted.length > 0) {
    return (
      <ProjectDetail
        currentView={currentView}
        selectedProject={selectedProject}
        searchTerm={searchTerm}
        imagePath={imagePath}
        videoPath={videoPath}
        imagePathHero={imagePathHero}
        imagePathFull={imagePathFull}
        detailClose={detailClose}
        detailNext={detailNext}
        detailPrev={detailPrev}
      />
    )
  }

  return (
    <>
      <LayoutHeader data={props.header} className={'projects__header'}/>
      <ProjectSearchInput setSearchTerm={setSearchTerm} />
      <div className={viewList ? 'projects--grid' : 'projects--list'}>
        <ProjectsSearchResults
          searchTerm={searchTerm}
          sorted={sorted}
          imagePathHero={imagePathHero}
          imagePath={imagePath}
          detailOpen={detailOpen}
        />
      </div>
    </>
  );
};

export default Projects;