import React from "react";

// svg import
import { ReactComponent as brand } from '../_images/svg/brand.svg';
// import { ReactComponent as skull } from '../_images/svg/skull.svg';

const iconTypes = {
  brand,
  // skull
};

const SvgComponent = ({ name, svgClass, ...props }) => {
  let Icon = iconTypes[name];
  return <Icon className={svgClass} {...props} />;
};

export default SvgComponent;