import React from "react";

// data
import Data from '../json/kidkrazydotnet-pageDescriptions.json';

// templates
import LayoutContainer from '../Layout/LayoutContainer';

// protected views
import Greeting from '../views/Greeting';
import SecureUiux from '../Layout/LayoutMarkdown';

// data
const data = Data;
const dataGreeting = data[5].greeting[0];
const dataUiUx = data[6].uiux[0];

// pathing
export const RoutesSecure = [
  {
    path: "/greeting",
    element: <LayoutContainer cClass="greeting"><Greeting header={dataGreeting} /></LayoutContainer>
  },
  {
    path: "/disney_institute",
    element:
      <LayoutContainer cClass="markdown">
        <SecureUiux
          header={dataUiUx}
          pageClass={'markdown'}
          endpoint={`${process.env.REACT_APP_CDN_SECURE}/disneyInstitute/disneyInstitute.md`}
          imagePath={`${process.env.REACT_APP_CDN_SECURE}/disneyInstitute/jpg/`}
        />
      </LayoutContainer>
  },
  {
    path: "/instaclick",
    element:
      <LayoutContainer cClass="markdown">
        <SecureUiux
          header={dataUiUx}
          pageClass={'markdown'}
          endpoint={`${process.env.REACT_APP_CDN_SECURE}/instaclick/instaclick.md`}
          imagePath={`${process.env.REACT_APP_CDN_SECURE}/instaclick/jpg/`}
        />
      </LayoutContainer>
  },
  {
    path: "/family_recipes",
    element:
      <LayoutContainer cClass="markdown">
        <SecureUiux
          header={dataUiUx}
          pageClass={'markdown'}
          endpoint={`${process.env.REACT_APP_CDN_SECURE}/familyRecipes/familyRecipes.md`}
          imagePath={`${process.env.REACT_APP_CDN_SECURE}/familyRecipes/jpg/`}
        />
      </LayoutContainer>
  }
];