import React from "react";
import Button from './TagButton'

const Social = ({ links }) => {

  const social = links.map((data, index) => {
    return (
      <li key={index}>
        <Button
          buttonType='anchor'
          className={data.name}
          title={data.name}
          destination={data.destination}
          icon={true}
          target="_blank"
        />
      </li>
    )
  })

  return (
    [social]
  )
}


export default Social;