import React, { Children } from "react";
import TagPicture from "./TagPicture";
import TagButton from './TagButton';

const Card = (props) => {
  const { hero, title, badge, onClick, summary } = props;

  return (
    <div className="card">
      {badge ? <span className="badge">{badge}</span> : ''}
      <TagPicture
        tabIndex="-1"
        hero={hero}
      />
      <h2 className="title">{title}</h2>
      <p>{summary}</p>
      <TagButton
        buttonType="button"
        onClick={onClick}
        className={`btn__next`}
        title={`View Project`}
        icon={true}
      />
    </div>
  )
};

export default Card;