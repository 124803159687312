import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import TagButton from './TagButton';
import SvgComponent from './SvgComponent';

const Slider = ({ data, nav, overlay, className }) => {
  const [slideCount, setSlideCount] = useState();
  // const [currentSlide, setCurrentSlide] = useState(0);

  const SliderNav = data.map((item, index) => {
    return (
      <a key={index} href={`#slide-${item.id}`}>slider-na fallback</a>
    )
  });

  const SliderSlides = data.map((item, index) => {
    return (
      <div key={index} className="slide" id={`slide-${item.id}`}>
        <div className="slide__details">
          <span className="small">{item.messageSmall}</span>
          <span className="large">{item.messageLarge}</span>
          {item.external
            ? <TagButton buttonType='anchor' destination={item.destination} className={'btn__' + item.buttonClass} icon={true} title={item.name} />
            : ''
          }
          {item.destination && !item.external
            ? <Link to={item.destination} className={'btn__' + item.buttonClass}><i />{item.name}</Link>
            : ''
          }
        </div>
        <div className="image slide__media">
          <SvgComponent name={item.iconName} svgClass={item.iconClass} />
        </div>
      </div>
    )
  });

  const initializeSlides = () => {
    let slideCount = data.length;
    let element = document.documentElement;

    // getComputedStyle(element).getPropertyValue("--slide-count");
    setSlideCount(slideCount)
    element.style.setProperty("--slide-count", slideCount);
  }

  const handleSliderNavClick = (event) => {
    const slide = document.querySelector(event.target.getAttribute("href"));
    if (!slide) return;

    if (slide.scrollIntoViewIfNeeded) {
      event.preventDefault();
      slide.scrollIntoViewIfNeeded();
    } else if (slide.scrollIntoView) {
      event.preventDefault();
      slide.scrollIntoView();
    }
  }

  // TODO a scroll does not update slideCount as of yet
  // const next = (event) => {
  //   event.preventDefault();
  //   if (currentSlide === slideCount - 1) {
  //     setCurrentSlide(0)
  //     document.getElementById(`slide-0`).scrollIntoView();
  //   }

  //   if (currentSlide !== slideCount - 1) {
  //     document.getElementById(`slide-${currentSlide + 1}`).scrollIntoView();
  //     setCurrentSlide(currentSlide + 1)
  //   }

  // }

  // const prev = (event) => {
  //   event.preventDefault();
  //   if (currentSlide === 0) {
  //     document.getElementById(`slide-${slideCount - 1}`).scrollIntoView();
  //     setCurrentSlide(slideCount - 1)
  //   }

  //   if (currentSlide !== 0) {
  //     document.getElementById(`slide-${currentSlide - 1}`).scrollIntoView();
  //     setCurrentSlide(currentSlide - 1)
  //   }
  // }

  useEffect(() => {
    let element = document.querySelector(".slider__nav")
    initializeSlides();
    element.addEventListener("click", (event) => handleSliderNavClick(event))

    return () => {
      element.removeEventListener("click", (event) => handleSliderNavClick(event))
    }
  }, []);

  return (
    <>
      <div className={className ? className : "slider"} >
        <div className={className ? `${className}__nav` : "slider__nav"}>
          {nav ? [SliderNav] : ''}
        </div>
        <div className={className ? `${className}__slides` : "slider__slides"}>
          {overlay ? <div className="slider__overlay" /> : ""}
          {[SliderSlides]}
        </div>

      </div>
      {/* TODO add scroll to update current slide & detection
      <div style={{ zIndex: 10, display: "flex", flexWrap: "nowrap" }}>
        <h1>{currentSlide}</h1>
        <button className="btn__text" onClick={prev}>Prev {currentSlide}</button>
        <button className="btn__text" onClick={next}>Next {currentSlide}</button>
      </div>
      */}
    </>
  )
}

export default Slider;

Slider.propTypes = {
  nav: PropTypes.bool,
  overlay: PropTypes.bool
}