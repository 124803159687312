import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import Loader from './Loader';
import Card from "./Card";

const Featured = ({ data, imagePath, detailOpen }) => {

  // state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const FeaturedList = data.map((item, index) => {
    const { type, hero, title, summary } = item;

    return (
      <Card
        key={index}
        hero={imagePath + type + '/' + hero}
        title={title}
        summary={summary}
        badge={type}
        onClick={(e) => detailOpen(index, e)}
      />
    )
  });
  console.log(data)

  if (loading) return <Loader />;

  if (error || !data) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <section className="featured">
      <div className="featured__header">
        <h2>Recent Projects</h2>
      </div>
      <div className="featured--grid">
        {FeaturedList}
        <p>To learn about my involvement with these and other projects, or to view full project details view my <NavLink
          to={'/projects'}
          aria-label="projects"
          tabIndex="0"
        >projects</NavLink>.</p>
      </div>
    </section>
  )
};

export default Featured;