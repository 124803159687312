import React, { useState } from "react";
import PropTypes from "prop-types";
import { useNavigate } from 'react-router-dom'

import { handleLogin, isLoggedIn } from "../services/auth";
import Button from "../components/TagButton"

const Login = () => {
  const [inputs, setInputs] = useState({});
  const [errorMessage, setErrorMessage] = useState('');
  let navigate = useNavigate();

  const handleChange = (e) => {
    const id = e.target.id;
    const value = e.target.value;
    setInputs(values => ({ ...values, [id]: value }))
  }

  const handleSubmit = (e, inputs) => {
    e.preventDefault();
    handleLogin(inputs)

    if (!isLoggedIn()) {
      setErrorMessage('Oops, try again');
    }

    if (isLoggedIn()) {
      navigate(`/greeting`)
    }
  }

  return (
    <form method="post">
      <header>
        <h2>Login</h2>
        <p>Please enter valid credentials for secured content.</p>
      </header>
      <div className="element">
        <label htmlFor="username">Username</label>
        <input
          id="username"
          type="text"
          name="username"
          autoComplete="username"
          onChange={handleChange}
          placeholder="Username"
          required
        />
      </div>
      <div className="element">
        <label htmlFor="password">Password</label>
        <input
          id="password"
          type="password"
          name="password"
          autoComplete="password"
          onChange={handleChange}
          placeholder="Password"
          required
        />
      </div>
      {/* {errorMessage ? <p className="error">{errorMessage}</p> : ''} */}
      <Button
        buttonType="button"
        className={`btn__basic ${errorMessage ? ' btn__submit--error' : ''}`}
        type="submit"
        title={errorMessage ? errorMessage : 'Log In'}
        name="Log In"
        value="Log In"
        icon="true"
        onClick={(e) => handleSubmit(e, inputs)}
      />
    </form>
  )
}

Login.propTypes = {
  username: PropTypes.string,
  password: PropTypes.string
};

export default Login;