import React, { useState, useEffect } from "react";
import './App.scss';
import { useRoutes } from "react-router-dom";

// authorization
import { isLoggedIn } from "./services/auth"

// routes
import { RoutesPublic } from './routes/routes-public';
import { RoutesSecure } from './routes/routes-secure';

// templates
import Header from './components/Header';

export default function App() {
  const [isDarkMode, setDarkMode] = useState(true);

  // routes
  let pubRoutes = RoutesPublic;
  let authRoutes = RoutesSecure;
  let allRoutes = [...pubRoutes, ...authRoutes];

  let authenticated = useRoutes(allRoutes);
  let inAuthenticated = useRoutes(pubRoutes);

  // listen for nav to lock body and toggle class
  useEffect(() => {
    let body = document.body;

    if (isDarkMode) {
      body.classList.add("mode__dark");
      body.classList.remove("mode__light");
    }
    if (!isDarkMode) {
      body.classList.add("mode__light");
      body.classList.remove("mode__dark");
    }

  }, [isDarkMode]);

  // <main className={!isDarkMode ? "mode__light" : "mode__dark"}>
  return (
    <main>
      <Header isDarkMode={isDarkMode} setDarkMode={setDarkMode} />
      {!isLoggedIn() ? inAuthenticated : authenticated}
    </main>
  )
}