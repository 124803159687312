import React from 'react';

const TagPicture = (props) => {
    const { className, onClick, onKeypress, media, width, height, hero, alt, loading, tabIndex } = props;
        
    return (
        <picture
            className={className}
            tabIndex={tabIndex ? tabIndex : '0'}
            onClick={onClick}
            onKeyPress={onKeypress ? onKeypress : onClick}
        >
            <source
                media={media}
                srcSet={hero}
            />
            <img
                width={width}
                height={height}
                src={hero}
                data-src={hero}
                alt={alt ? alt : hero}
                loading={loading ? loading : 'auto'}
            />
        </picture>
    )
}

export default TagPicture;