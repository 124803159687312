import React from 'react';

const Video = (props) => {
    const {width, height, controlsList, poster, preload, source, type} = props;
    
    return (
        <video
            width={width}
            height={height}
            controls
            controlsList={controlsList}
            poster={poster}
            preload={preload}
            autopictureinpicture={0}
        >
            <source
                src={source}
                type={'video/'+ type}
            />
            'Your browser does not support the video tag.'
        </video>
    )
}

export default Video;