import React from "react";
import { NavLink } from "react-router-dom";
import { isLoggedIn, logout } from "../services/auth"

import TagButton from './TagButton';
import Social from './Social.js';

const NavPrimary = ({ isNav, toggleNav, links, isDarkMode, darkMode, social }) => {

  const handleClick = () => {
    if (isLoggedIn) {
      logout()
    }
    toggleNav()
  }

  const Copyright = (
    <span className="copyright">©2022 kidkrazy. All rights reserved.</span>
  );

  const NavLinks = links.map((item, i) =>
    <li key={i}>
      <NavLink
        to={'/' + item.destination}
        className={({ isActive }) => isActive ? `nav__link ${item.class} nav__link--active` : `nav__link ${item.class}`}
        onClick={isNav ? () => toggleNav() : ''}
        aria-label={item.name}
        tabIndex="0"
      >
        <i /> {item.name}
      </NavLink>
    </li>
  );

  const LogUser = (
    <li >
      <NavLink
        to={!isLoggedIn() ? '/login' : '/'}
        className={({ isActive }) => isActive ? 'nav__link nav__link--in nav__link--active' : 'nav__link nav__link--out'}
        onClick={handleClick}
        aria-label={!isLoggedIn() ? 'Login' : 'Logout'}
        tabIndex="0"
      >
        <i />
        {!isLoggedIn() ? 'Login' : 'Logout'}
      </NavLink>
    </li>
  );

  const Mode = (
    < li >
      <TagButton
        buttonType='button'
        className={`mode ${!isDarkMode ? ' nav__link nav__link--light' : ' nav__link nav__link--dark'}`}
        title={`Toggle ${!isDarkMode ? ' Dark ' : ' Light '} Mode`}
        aria={`Toggle ${!isDarkMode ? ' Dark ' : ' Light '} Mode`}
        onClick={() => darkMode()}
        icon={true}
      />
    </li >
  );

  return (
    <nav className={isNav ? 'nav nav__menu nav__menu--visible' : 'nav nav__menu'} role="navigation">
      <ul className="nav__menu--container">
        {NavLinks}
        {LogUser}
        {Mode}
      </ul>
      <ul className="social">
        <Social links={social} />
      </ul>
      {Copyright}
    </nav>
  )
}

export default NavPrimary;