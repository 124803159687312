import React from "react";

// data
import Data from '../json/kidkrazydotnet-pageDescriptions.json';

// templates
import LayoutContainer from '../Layout/LayoutContainer';

// public views
import Home from '../views/Home';
import About from '../views/About';
import Projects from '../views/Projects';
import Contact from '../views/Contact';
import Oops from '../views/Oops';
import Login from '../views/Login';

// header data
const data = Data;
const dataOops = data[4].oops[0];
const dataProjects = data[7].projects[0];

// pathing
export const RoutesPublic = [
  {
    path: "/contact",
    element: <LayoutContainer cClass="contact"><Contact /></LayoutContainer>
  },
  {
    path: "/projects",
    element: <LayoutContainer cClass="projects"> <Projects header={dataProjects} /> </LayoutContainer>
  },
  {
    path: "/about",
    element: <LayoutContainer cClass="about"> <About /> </LayoutContainer>
  },
  {
    path: "*",
    element: <LayoutContainer cClass=""> <Oops header={dataOops} /> </LayoutContainer>
  },
  {
    path: "/login",
    element: <LayoutContainer cClass="login"> <Login /> </LayoutContainer>
  },
  {
    path: "/",
    element: <Home data={dataProjects} />
  }
];