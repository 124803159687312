import React from 'react';

const Iframe = (props) => {
    const {title, src, controls} = props;

    return (
        <iframe
            title={title}
            src={src}
            controls={controls}
            width=""
            height=""
            frameBorder="0"
            allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            loading="lazy"
        />
    )
}

export default Iframe;