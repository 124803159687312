import React from "react";
import SvgComponent from './../components/SvgComponent';

const PageHeader = ({ data, children, className }) => {

  return (
    <header className={className}>
      <h1 className="heading" tabIndex="0">{data.heading}</h1>
      <h2 className="subheading">{data.subheading}</h2>
      <hr className="divider" />
      {children}
      <p className="overview">{data.overview}</p>
      <div className="image">
        <SvgComponent name={data.iconName} svgClass={data.iconClass} />
      </div>
    </header>
  )
}

export default PageHeader;