import React from 'react';
import PropTypes from "prop-types";
import Button from './TagButton';

const GallerySingle = (props) => {

  const Controls =
    <div className="controls">
      <Button
        buttonType='button'
        className="controls--close"
        title='Close'
        name='Close'
        icon={true}
        onClick={(e) => props.closeGallery(e)}
      />
    </div>
    ;

  return (
    <div className="gallery">
      {Controls}
      <div className="gallery--media">
        <img src={props.data} alt={props.data} />
      </div>
    </div>
  );
}

GallerySingle.propTypes = {
  data: PropTypes.string,
  onClick: PropTypes.func
};

export default GallerySingle;