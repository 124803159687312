import React from "react";
import TagInput from './TagInput';

const ProjectSearchInput = ({ setSearchTerm }) => {

  return (
    <TagInput
      inputType={'input'}
      id={'search'}
      className={'search'}
      label={'search'}
      type={'search'}
      onChange={(event) => setSearchTerm(event.target.value)}
      placeholder='Search projects...'
      icon={true}
    >
    </TagInput>

  )
}

export default ProjectSearchInput;