import React, { useEffect, useState } from "react";

import { fetchData } from '../utilities/async';
import LayoutHeader from '../Layout/LayoutHeader';
import Loader from '../components/Loader';
import TagDetail from '../components/TagDetail';

const About = (props) => {
  // data
  const endpoint = '/json/kidkrazydotnet-about.json';
  // state
  const [dataUrl, setDataUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(endpoint).then((ActualData) => {
      setDataUrl(ActualData.data);
      setError(null);
      // console.log('data', ActualData.data)
    })
      .catch((err) => {
        setError(err.message);
        setDataUrl(null);
      })
      .finally(() => {
        setLoading(false);
      })
  }, [endpoint]);

  if (loading) return <Loader />;

  if (error || !dataUrl) {
    return <p>There was an error loading your data!</p>;
  }

  return (
    <>
      <LayoutHeader data={dataUrl[0].about[0]} className={'about__header'}/>
      <section className="about__content">

        <div className="about__content--media img" />

        <div className="about__content--content">
          <h2 id={`#${dataUrl[0].me[0].heading}`} tabIndex="0">{dataUrl[0].me[0].heading}</h2>
          <h5>{dataUrl[0].me[0].subheading}</h5>
          <hr className="divider" />
          <p>{dataUrl[0].me[0].overview}</p>
          <p className="spacer"> </p>

          <h3 id={`#${dataUrl[1].site[0].heading}`} tabIndex="0">{dataUrl[1].site[0].heading}</h3>
          <h5>{dataUrl[1].site[0].subheading}</h5>
          <hr className="divider" />
          <p>{dataUrl[1].site[0].overview}</p>
          <p className="spacer"> </p>

          <h3 id={`#${dataUrl[2].tech[0].heading}`} tabIndex="0">{dataUrl[2].tech[0].heading}</h3>
          <h5>{dataUrl[2].tech[0].subheading}</h5>
          <hr className="divider" />
          <p>{dataUrl[2].tech[0].overview}</p>
          <p className="spacer"> </p>

          {dataUrl[3].skills.map((skill, index) => {
            return (
              <TagDetail
                key={index}
                summary={skill.skill}
                details={<p>{skill.skillset}</p>}
              />
            )
          })
          }
        </div>
      </section>
    </>
  )
}

export default About;