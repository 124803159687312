import React, { useState, useEffect } from "react";
import { fetchData } from '../utilities/async';
import Hero from "../json/kidkrazydotnet-hero.json";
import Slider from "../components/Slider";
import Featured from "../components/Featured";
import ProjectDetail from '../components/ProjectDetail';

const Home = () => {
  const { REACT_APP_CDN } = process.env;
  //pathing
  const imagePath = REACT_APP_CDN + '/_images/thumbs/';
  const imagePathFull = REACT_APP_CDN + '/images/';
  const imagePathHero = REACT_APP_CDN + '/images/hero/';

  // state
  const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);
  const [dataAll, setDataAll] = useState([]);

  const [details, setDetails] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  // const currentView = dataAll[selectedProject];

  // data
  const endpointWeb = '/json/kidkrazydotnet-web.json';

  // Run once on first render
  useEffect(() => {
    window.scrollTo(0, 0);

    fetchData(endpointWeb)
      .then((web) => {
        setDataAll(web.data)
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      })
  }, []);

  const featured = dataAll.filter(feature => dataAll.featured === 1);

  const featuredList = dataAll.reduce((acc, item, index) => {
    if (item.featured === '0') {
      acc.push(item);
    }
    return acc;
  }, []);

  const detailOpen = (index) => {
    setSelectedProject(featuredList[index]);
    setDetails(true);
  }

  const detailClose = () => {
    setDetails(!setDetails);
  }

  const heroArray = Hero;

  if (details) {
    
    return (
      <ProjectDetail
        currentView={selectedProject}
        selectedProject={selectedProject}
        searchTerm={0}
        imagePath={imagePath}
        videoPath={''}
        imagePathHero={imagePathHero}
        imagePathFull={imagePathFull}
        detailClose={detailClose}
        detailNext={''}
        detailPrev={''}
      />
    )
  }

  return (
    <section className="home">
      <Slider nav={true} data={heroArray} />
      <Featured data={featuredList} imagePath={imagePath} detailOpen={detailOpen} />
    </section>
  )
}

export default Home;