import React from "react";
import Card from './Card';

const ProjectsSearchResults = ({ sorted, searchTerm, imagePathHero, imagePath, detailOpen }) => {

  const ProjectList = sorted.reduce((acc, item, index) => {
    if (searchTerm === '') {
      acc.push({ index });
    }
    else if (item.title.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.date.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.keywords.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.role.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    else if (item.type.toLowerCase().includes(searchTerm.toLowerCase())) {
      acc.push({ index });
    }
    return acc;
  }, []).map(({ index }) => {
    const item = sorted[index];
    const { type, hero, title, summary } = item;

    return (
      <Card
        key={index}
        hero={imagePath + type + '/' + hero}
        title={title}
        summary={summary}
        badge={type}
        onClick={(e) => detailOpen(e, index)}
      />
    )
  });

  return (
    [ProjectList]
  )
}

export default ProjectsSearchResults;