import React from "react";

const TagFigure = (props) => {

  const {
    className, src, alt, loading, width, height, caption, tabIndex, onClick, onKeyPress
  } = props;

  return (
    <figure
      className={className}
      tabIndex={tabIndex ? tabIndex : '0'}
      onClick={onClick}
      onKeyPress={onKeyPress ? onKeyPress : onClick}
    >
      <img
        width={width}
        height={height}
        src={src}
        data-src={src}
        alt={alt}
        loading={loading ? loading : 'auto'}
      />
      {caption || props.children
        ? <figcaption>{caption}{props.children}</figcaption>
        : ''
      }
    </figure>
  )
}

export default TagFigure;