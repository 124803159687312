export async function fetchData(endpoint) {
  const dataURL = process.env.REACT_APP_CDN + endpoint;
  const response = await fetch(dataURL);
  const data = await response.json(response);
  return { data };
  // console.log('function data', data)
}

export async function fetchMd(endpoint) {
  const dataURL = endpoint;
  const response = await fetch(dataURL);
  const data = await response.text(response);
  return { data };
  // console.log('function data', data)
}