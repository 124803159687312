import React, { useEffect } from "react";
import LayoutHeader from '../Layout/LayoutHeader';

const Greeting = (props) => {
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LayoutHeader data={props.header} />
    )
  }
  
  export default Greeting;