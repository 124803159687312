import React from "react";

const TagDetail = ({ className, summary, details, icon }) => {

  return (
    <details
      className={className}
      open={false}
    >
      <summary>
        <span>
        {summary}
        </span>
        {icon ? <i /> : ''}
      </summary>
      {details}
    </details>
  )
}

export default TagDetail;