import React, { useEffect } from "react";

import ItemControls from './ItemControls';
import WebItem from './WebItem';
import VideoItem from './VideoItem';
import DesignItem from './DesignItem';
// import { getScrollEval } from '../utilities/scroll';

const ProjectDetail = ({ currentView,
  selectedProject,
  searchTerm,
  imagePath,
  videoPath,
  imagePathHero,
  imagePathFull,
  detailClose,
  detailNext,
  detailPrev }) => {

  const {
    type, hero, title, summary, summaryFull,
    role, keywords, date, protocol, link, screenshots,
    runtime, poster, file, srcType
  } = currentView;

  useEffect(() => {
    // window.addEventListener('scroll', (event) => getScrollEval(event, 'h2'));

    // return () => {
    //   window.removeEventListener('scroll', getScrollEval);
    // }
  }, [selectedProject])


  return (
    <>
      {type === 'web' ?
        <WebItem
          key={selectedProject}
          className='projects__detail'
          hero={imagePathHero + type + '/' + hero}
          title={title}
          summary={summary}
          summaryFull={summaryFull}
          role={role}
          keywords={keywords}
          date={date}
          protocol={protocol}
          link={link}
          screenshotsPath={imagePathFull + type + '/'}
          screenshots={screenshots}
        />
        : ''
      }

      {type === 'video' ?
          <VideoItem
            key={selectedProject}
            className='projects__detail'
            title={title}
            summary={summary}
            summaryFull={summaryFull}
            role={role}
            keywords={keywords}
            date={date}
            runtime={runtime}
            poster={poster !== null ? imagePath + type + '/' + poster : 'placeholder'}
            source={file !== null ? videoPath + file : ''}
            src={link !== null ? link : ''}
            type={srcType !== null ? srcType : ''}
          />
          : ''
      }

      {type === 'design' ?
          <DesignItem
            key={selectedProject}
            className='projects__detail'
            hero={imagePathHero + type + '/' + hero}
            title={title}
            summary={summary}
            summaryFull={summaryFull}
            role={role}
            keywords={keywords}
            date={date}
            screenshotsPath={imagePathFull + type + '/'}
            screenshots={screenshots}
          />
          : ''
      }
      <ItemControls
        className='projects__controls '
        close={() => detailClose()}
        next={searchTerm === '' ? () => detailNext() : ''}
        previous={searchTerm === '' ? () => detailPrev() : ''}
      />
    </>
  )
}
export default ProjectDetail;