import React from "react";

const LayoutContainer = (props) => {
  return (
    <section className="content">
      <article className={props.cClass}>
        {props.children}
      </article>
    </section>
  )
}

export default LayoutContainer;